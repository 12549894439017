import React, { useContext } from "react";
import Layout from "../components/Layout";
import { LangStateContext } from "../context/LangContext";
import Seo from '../components/seo/Seo';
import '../styles/PrivacyPolicyPage.scss';

const PrivacyPolicyPage = () => {
  const { isIndoSelected } = useContext(LangStateContext);
  console.log(isIndoSelected);

  return (
    <Layout>
        <Seo 
            title='Titik Pintar - Privacy policy'
            description='This policy sets out the basis on which PT. Fammi Indonesia Edutech'
            keywords='Privacy, Policy, Titik Pintar'
        />
     <div id="page" className='privacy-page'>
            <div className='privacy-page-text'>
                <div id="primary" class="content-area">
                    <main id="main" class="site-main">
                        <article id="post-410" class="post-410 page type-page status-publish hentry">
                            <header class="entry-header">
                                <h1 class="entry-title">Privacy policy</h1>
                            </header>
                            <div class="entry-content">
                                <p>This policy sets out the basis on which PT. Fammi Indonesia Edutech (together with our subsidiaries, our holding company, subsidiaries of our holding company from time to time, collectively called "<strong>Titik Pintar</strong>", "<strong>Sahabat Pintar</strong>", or &ldquo;<strong>we</strong>&rdquo;) collects personal data from you and how we process such data.</p>
                                <p>By visiting our websites: titikpintar.id or sahabatpintar.id (our &ldquo;<strong>Site</strong>&rdquo;) or using Titik Pintar or any other applications or software we provide from time to time (collectively our &ldquo;<strong>App</strong>&rdquo;), you accept and consent to the practices set out below.</p>
                                <h1>1 Collection of information</h1>
                                <p>1.1 For the purposes outlined in Clause 2, we may collect and process the following information about you:</p>
                                <ul>
                                    <li><em>Information you give us</em> - information that you provide us (which may include your name, address, email address, telephone number, credit card information and other personal description) by filling in forms on our Site or our App, or by corresponding with us (by phone, email or otherwise), for example:
                                        <ul>
                                            <li>when you register for an account with us on our Site and in our App;</li>
                                            <li>when you report any problem to us;</li>
                                            <li>when you use certain features on our Site or our App;</li>
                                            <li>when you request any support from us; or</li>
                                            <li>when you complete any survey or questionnaire we send you.</li>
                                        </ul>
                                    </li>
                                    <li><em>Information we collect about you</em> - information automatically collected when you visit our Site or use our App, for example:
                                        <ul>
                                            <li>technical information, including the Internet protocol (IP) address used to connect your computer to the Internet and your log-in information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform;</li>
                                            <li>details of any transactions, purchases and payments you made on our Site and in our App; and</li>
                                            <li>information about your visit, including the full Uniform Resource Locators (URLs), clickstream to, through and from our site (including date and time), products you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page, and any phone number used to call our customer service number.</li>
                                        </ul>
                                    </li>
                                </ul>
                                <p>1.2 We only retain personal data for so long as it is necessary. Data may be archived as long as the purpose for which the data was used still exists.</p>
                                <h1>2 Uses made of the information</h1>
                                <p>2.1 We use information held about you for the following purposes:</p>
                                <ul>
                                    <li>providing, improving and developing our services;</li>
                                    <li>researching, designing and launching new features or products;</li>
                                    <li>presenting content and information in our Site and our App in the most effective manner for you and for the device you use;</li>
                                    <li>providing you with alerts, updates, materials or information about our services or other types of information that you requested or signed up to;</li>
                                    <li>in any part of the world;</li>
                                    <li>responding or taking part in legal proceedings, including seeking professional advice;</li>
                                    <li>for direct marketing purposes (please see further details in Clause 2.2 below);</li>
                                    <li>communicating with you and responding to your questions or requests; and</li>
                                    <li>purposes directly related or incidental to the above.</li>
                                </ul>
                                <p>2.2 We intend to use your personal data in direct marketing (i.e. offering or advertising products or services by sending the relevant information directly to you). We require your consent specifically for this purpose and<strong> you may opt out any time</strong>. For the purpose of this clause:</p>
                                <ul>
                                    <li>the personal data that may be used in direct marketing are those that you provide to us or we collect from you under Clause 1.1 above;</li>
                                    <li>the relevant information may be sent to you by e-mail or messaging service;</li>
                                    <li>you may opt out any time by sending an email to hi@titikpintar.id. We will cease to send you marketing information without charge.</li>
                                </ul>
                                <h1>3 Disclosure of your information</h1>
                                <p>We will keep your personal data we hold confidential but you agree we may provide information to:</p>
                                <ul>
                                    <li>any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries, as defined in Section 5 of the Companies Act (Chapter 50);</li>
                                    <li>personnel, agents, advisers, auditors, contractors, financial institutions, and service providers in connection with our operations or services (for example staff engaged in the fulfilment of your order, the processing of your payment and the provision of support services);</li>
                                    <li>our overseas offices, affiliates, business partners and counterparts (on a need-toknow basis only);</li>
                                    <li>persons under a duty of confidentiality to us;</li>
                                    <li>persons to whom we are required to make disclosure under applicable laws and regulations in any part of the world; or</li>
                                    <li>actual or proposed transferees of our operations (or a substantial part thereof) in any part of the world.</li>
                                </ul>
                                <h1>4 Cookies</h1>
                                <p>4.1 Our Site uses cookies to distinguish you from other users of the Site. This helps us to provide you with a good experience when you browse our Site and also allows us to improve our Site.</p>
                                <p>4.2 A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree to the use of cookies. Cookies contain information that is transferred to your computer's hard drive.</p>
                                <p>4.3 We use persistent cookies and session cookies. A persistent cookie stays in your browser and will be read by us when you return to our Site or a partner site that uses our services. Session cookies only last for as long as the session (usually the current visit to a website or a browser session).</p>
                                <p>4.4 We use the following cookies:</p>
                                <p>(a) Strictly necessary cookies &ndash; These are cookies that are required for the operation of our Site. They include, for example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing services.</p>
                                <p>4.5 You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you do so, you may not be able to access all or parts of our Site.</p>
                                <p>4.6 We may use third-party web services on our Site. The service providers that administer these services use technologies such as cookies (which are likely to be analytical/performance cookies or targeting cookies), web server logs and web beacons to</p>
                                <p>help us analyse how visitors use our Site and make the information displayed on it more relevant to your interests. The information collected through these means (including IP addresses) is disclosed to these service providers. These analytics services may use the data collected to contextualise and personalise the marketing materials of their own advertising network.</p>
                                <h1>5 Third-party sites</h1>
                                <p>Our Site, our App or our communication with you may from time to time contain links to third-party websites over which we have no control. If you follow a link to any of these websites, please note that they have their own practices and policies. We encourage you to read the privacy policies or statements of these websites understand your rights. We accept no responsibility or liability for any practices of third-party websites.</p>
                                <h1>6 Security</h1>
                                <p>6.1 All information you provide to us is stored on our secure servers.</p>
                                <p>6.2 Any payment transactions will be encrypted using TLS/SSL technology.</p>
                                <p>6.3 Where we have given you (or where you have chosen) a password that enables you to access certain parts of the Site, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.</p>
                                <p>6.4 We restrict access to personal information to our employees, service providers and contractors on a strictly need-to-know basis and ensure that those persons are subject to contractual confidentiality obligations.</p>
                                <p>6.5 We review our information collection, storage and processing practices from time to time to guard against unauthorised access, processing or use.</p>
                                <p>6.6 Please note, however, the transmission of information via the Internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our Site; any transmission is at your own risk.</p>
                                <h1>7 Data transfer</h1>
                                <p>7.1 The data that we collect from you may be transferred to, and stored at, a destination outside Singapore. It may also be processed by persons referred to in Clause 3 above who operate outside Singapore.</p>
                                <p>7.2 By submitting your personal data you agree to this transfer, storing or processing of data outside Singapore. We will take all steps reasonably necessary to ensure that your data is treated securely in accordance with this privacy policy.</p>
                                <h1>8 Your rights</h1>
                                <p>8.1 You have the right to:</p>
                                <ul>
                                    <li>check whether we hold personal data about you;</li>
                                    <li>access any personal data we hold about you; and</li>
                                    <li>require us to correct any inaccuracy or error in any personal data we hold about you.</li>
                                </ul>
                                <p>8.2 Any request under Clause 8.1 may be subject to a small administrative fee to meet our cost in processing your request.</p>

                                <h1>9 Children &#8217;s privacy</h1>
                                <p>
                                    Our Service does address an audience under the age of 13 (&#8220;<strong>Children</strong>
                                    &#8220;). We collect personally identifiable information from children under 13. If you are a parent or guardian please be aware that your Children has provided us with personal information. If we become aware that we have collected personal information from a child under age 13 without parental consent, we will take steps to remove that information from our servers.
                                </p>

                                <h1>10 Changes to our privacy policy</h1>
                                <p>We may amend this policy from time to time by posting the updated policy on our Site. By continuing to use our Site and our App after the changes come into effect means that you agree to be bound by the revised policy.</p>
                                <h1>11 Contact us</h1>
                                <p>If you have any questions, comments or requests regarding personal data, please address them to:</p>
                                <p>Robbert Deusing, hi@titikpintar.id</p>
                                <p>Last updated: <strong>01-12-2020</strong></p>
                            </div>
                        </article>
                    </main>
                </div>
            </div>
        </div>
    </Layout>
  );
};

export default PrivacyPolicyPage;
